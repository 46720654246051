
.font-0-8vw { font-size: 0.8rem !important; }
.font-0-6vw { font-size: 0.6rem !important; }
.font-normal { font-size: 1rem !important; }
.font-2vw { font-size: 2.25rem !important; }
.font-3vw { font-size: 3.25rem !important; }
.font-1-2vw { font-size: 1.2rem !important; }
.font-1-5vw { font-size: 1.5rem !important; }
.font-2-5vw { font-size: 2.5rem !important; }
.font-4vw { font-size: 4rem !important; }
.font-14vw { font-size: 6rem !important; }

.lines {
    height: 100%;
    width: 5px;
    background: black;
}

.wave-container {
    position: relative;
    overflow: hidden;
    background-color: #1976d2; /* Couleur de fond du div */
    color: white; /* Couleur du texte */
    padding: 50px 20px; /* Espacement à l'intérieur du div */
  }
  
.wave-container::before {
    content: '';
    position: absolute;
    top: -50px; /* Ajustez la position selon votre design */
    left: 0;
    width: 100%;
    height: 100px; /* Hauteur de la vague */
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%23ffffff" fill-opacity="1" d="M0,128L30,117.3C60,107,120,85,180,69.3C240,53,300,43,360,58.7C420,75,480,117,540,144C600,171,660,181,720,176C780,171,840,149,900,117.3C960,85,1020,43,1080,42.7C1140,43,1200,85,1260,101.3C1320,117,1380,107,1410,101.3L1440,96L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path></svg>') no-repeat center;
    background-size: cover; /* Ajuster la taille de la vague */
    z-index: -1; /* Assurez-vous que la vague est derrière le contenu */
  }

#nameDiv>div {
    animation: nameEnter ease-in-out 0.4s;
    animation-fill-mode: backwards;
}

@media screen and (max-width: 1370px) {
    .font-0-8vw { font-size: 0.7rem !important; }
    .font-0-6vw { font-size: 0.5rem !important; }
    .font-normal { font-size: 0.9rem !important; }
    .font-2vw { font-size: 2rem !important; }
    .font-3vw { font-size: 3rem !important; }
    .font-1-2vw { font-size: 1.1rem !important; }
    .font-1-5vw { font-size: 1.4rem !important; }
    .font-2-5vw { font-size: 2.4rem !important; }
    .font-4vw { font-size: 3.75rem !important; }
    .font-14vw { font-size: 5rem !important; }
}

@media screen and (max-width: 1024px) {
    /* .hanldeTop { margin-top: -50px !important; } */
    .font-0-8vw { font-size: 0.5rem !important; }
    .font-0-6vw { font-size: 0.3rem !important; }
    .font-normal { font-size: 0.7rem !important; }
    .font-2vw { font-size: 1.5rem !important; }
    .font-3vw { font-size: 2.8rem !important; }
    .font-1-2vw { font-size: 0.9rem !important; }
    .font-1-5vw { font-size: 1.2rem !important; }
    .font-2-5vw { font-size: 2.2rem !important; }
    .font-4vw { font-size: 3rem !important; }
    .font-14vw { font-size: 4rem !important; }
    .gridTo6>div:not(:first-child) { width: 48% }
}

@media screen and (max-width: 850px) {
    .font-0-8vw { font-size: 0.4rem !important; }
    .font-0-6vw { font-size: 0.2rem !important; }
    .font-normal { font-size: 0.6rem !important; }
    .font-2vw { font-size: 1rem !important; }
    .font-3vw { font-size: 1.5rem !important; }
    .font-1-2vw { font-size: 0.7rem !important; }
    .font-1-5vw { font-size: 0.8rem !important; }
    .font-2-5vw { font-size: 1.4rem !important; }
    .font-4vw { font-size: 2rem !important; }
    .font-14vw { font-size: 3rem !important; }
}

@media screen and (max-width: 650px) {
    .hanldeTop { padding-top: 70px !important; }
    .font-normal { font-size: 3vw !important; }
    .font-2vw { font-size: 6vw !important; }
    .font-1-2vw { font-size: 4.2vw !important; }
    .font-1-5vw { font-size: 4.5vw !important; }
    .font-2-5vw { font-size: 7.5vw !important; }
    .ToColumn {
        flex-direction: column !important;
        width: 100% !important;
    }
    .ToFullSize {
        width: 100% !important;
    }
    .font-3vw { font-size: 9vw !important; }
    .font-4vw { font-size: 12vw !important; }
    .font-0-8vw { font-size: 2.4vw !important; }
    .font-0-6vw { font-size: 1.8vw !important; }
}

@keyframes nameEnter {
    from {
        translate: 25px;
        rotate: -25deg;
        opacity: 0;
    }
}