
.fixNavbar {
    margin: 0;
    position: fixed !important;
    top: 0 !important;
    width: 100%;
}
.upToUp {
    bottom: 5.5vh !important;
}

@keyframes fromTop {
    from {
        top: -150px;
    }
}

.navigationBar {
    transition: 0.2s;
}

#returnUp {
    position: fixed;
    transition: 0.15s;
    display: none;
    right: 1vw;
    bottom: 0;
    z-index: 1001;
    cursor: pointer;
}

#homeMin {
    position: fixed;
    transition: 0.15s;
    display: none;
    left: 0;
    bottom: 0;
    z-index: 1001;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    border-radius: 15px;
}
.dropdown2 {
    position: relative;
    display: inline-block;
    border-radius: 15px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    border-radius: 15px;
    display: none;
    position: absolute;
    background-color: white;
    min-width: 40vw;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
.dropdown-content2 {
    border-radius: 15px;
    display: none;
    position: absolute;
    background-color: white;
    min-width: 420px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 2vw;
}


/* Links inside the dropdown */
.dropdown-content > .dropdownBtn {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    width: 100% !important;
}
.dropdown-content2 > .dropdownBtn2 {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    width: 100% !important;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown2:hover .dropdown-content2 {
    display: block;
}


@media screen and (max-width: 1480px) {
    .fromNavBar {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1040px) {
    .dropdown-content {
        width: 55vw;
    }
}

@media screen and (min-width: 651px) {
    .disapear {
        display: none !important;
    }
}

@media screen and (max-width: 650px) {
    .navigationBar {
        width: 100% !important;
        /* margin-top: -15px !important; */
    }

    
    .navbarImage {
        width: 15vw;
    }

    .LogoDiv {
        width: 110% !important;
    }
    .fixNavbar {
        top: 3vh !important;
    }
    .dropdown-content {
        width: 85vw;
    }
    .showing {
        display: none !important;
    }
    #homeMin {
        display: block;
    }
}

@media screen and (max-width: 450px) {
        
    .LogoDiv {
        width: 90px !important;
    }
}

@media screen and (max-width: 375px) {
        
    .LogoDiv {
        width: 70px !important;
    }
}