
@media screen and (max-width: 580px) {
    .theNotFound > div {
        font-size: 40vw !important;
    }
    .textNotFound {
        font-size: 4vw !important;
    }
    .goHomeBtn {
        font-size: 3vw !important;
    }
}